/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";

//import './table.css';
import { useQuery } from "@apollo/client";
import { GET_ALL_JOBS } from "../../../../Graphql/Queries";
import "./filtering.css";
export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <input
        className="form-control input-search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div>
      Search :{" "}
      <input
        className="ml-2 input-search form-control"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        style={{ width: "20%" }}
      />
    </div>
  );
};

export const Job = () => {
  const [data, setData] = useState([]);
  const [inputfield, setInputfield] = useState({
    first: 10,
    page: 1,
  });
  console.log(data);
  const Filter = (e) => {
    const { value } = e.target;
    if (value === "acceptable Jobs") {
      setInputfield({ ...inputfield, is_approved: true, is_rejected: false });
      reftchQurey();
    } else if (value === "non-acceptable Jobs") {
      setInputfield({ ...inputfield, is_approved: false, is_rejected: true });
      reftchQurey();
    } else if (value === "pending Jobs") {
      setInputfield({ ...inputfield, is_approved: false, is_rejected: false });
      reftchQurey();
    } else if (value === "all") {
      const { is_approved, is_rejected, ...rest } = inputfield;
      setInputfield({ ...rest });
      reftchQurey();
    }
  };
  const {
    data: queryData,
    error,
    loading,
    refetch: reftchQurey,
  } = useQuery(GET_ALL_JOBS, {
    variables: inputfield,
  });
  // Calling Mutations

  const paginatorInfo = queryData?.appliedJobs?.paginatorInfo;
  useEffect(() => {
    if (!loading && !error) {
      setData(queryData?.appliedJobs?.data);
    }
  }, [queryData, loading]);
  const keys = [
    "user.first_name",
    "user.last_name",
    "user.job_title",
    "user.email",
    "user.phone",
    "country",
    "city",
    "address",
  ];

  const COLUMNS = [
    {
      Header: "Profile Picture",
      Footer: "Profile Picture",
      accessor: "user.profile_image",
      Cell: ({ value }) => {
        return (
          <>
            <a target="_blank" href={value} rel="noreferrer">
              <img
                src={value}
                width={40}
                height={40}
                className="rounded"
                alt=""
              />
            </a>
          </>
        );
      },
    },
    ...keys.map((key) => {
      const header = key.replace(/\.|_/g, " "); // Replace dots and underscores with spaces
      const footer = key.split(".").pop().replace(/\.|_/g, " "); // Remove dots, underscores, and get the last part of the key

      return {
        Header: header,
        Footer: footer,
        accessor: key,
        Filter: ColumnFilter,
      };
    }),
    {
      Header: "View Cv",
      Footer: "View Cv",
      accessor: "cv",
      Cell: ({ value }) => {
        return (
          <>
            <a
              download
              href={value}
              className="btn btn-sm btn-primary text-center"
            >
              <i className="fa fa-file text-white" />
            </a>
          </>
        );
      },
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: "",
      Cell: ({ row }) => {
        const { is_approved, is_rejected } = row.original;
        return (
          <>
            {is_approved && !is_rejected ? (
              <>
                <span class="badge badge-success">Acceptable</span>
              </>
            ) : is_rejected && !is_approved ? (
              <>
                <span class="badge badge-danger">Non Acceptable</span>
              </>
            ) : (
              <>
                <span class="badge badge-secondary">Pending</span>
              </>
            )}
          </>
        );
      },
      Filter: ColumnFilter,
    },
  ];
  const [columns] = useState(COLUMNS);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,

    setGlobalFilter,
  } = tableInstance;
  const { globalFilter } = state;

  const handleFirstPage = () => {
    setInputfield({ ...inputfield, page: 1 });
  };

  const handlePreviousPage = () => {
    setInputfield({ ...inputfield, page: inputfield.page - 1 });
  };

  const handleNextPage = () => {
    setInputfield({ ...inputfield, page: inputfield.page + 1 });
  };

  const handleLastPage = () => {
    setInputfield({ ...inputfield, page: paginatorInfo.lastPage });
  };

  useEffect(() => {
    // Update the inputfield state when the paginator data changes
    if (paginatorInfo) {
      setInputfield((prevInputfield) => ({
        ...prevInputfield,
        page: paginatorInfo.currentPage,
      }));
    }
  }, [paginatorInfo]);

  return (
    <>
      {loading ? <div className="loader"></div> : null}
      <div className="card">
        <div>
          <div className="card-header">
            <h4 className="card-title float-left">Jobs</h4>
            <div>
              <div className="float-end">
                <select
                  className="form-control"
                  name="filter"
                  onChange={Filter}
                >
                  <option selected disabled hidden>
                    Filter Jobs...
                  </option>
                  <option value={"all"}>All Jobs</option>
                  <option value={"acceptable Jobs"}>Acceptable Jobs</option>
                  <option value={"non-acceptable Jobs"}>
                    Non Acceptable Jobs
                  </option>
                  <option value={"pending Jobs"}>Pending Jobs</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table
              {...getTableProps()}
              id="table-to-export" // Add the id attribute
              className="table display"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {/* {column.canFilter ? column.render("Filter") : null} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between">
            <span>
              Page{" "}
              <strong>
                {paginatorInfo?.currentPage} of {paginatorInfo?.total}
              </strong>
              {""}
            </span>
            <span className="table-index">
              View Per page :{" "}
              <select
                type="number"
                className="ml-2"
                onChange={(e) => {
                  const pageNumber = e.target.value;
                  setInputfield({
                    ...inputfield,
                    first: Number(pageNumber),
                  });
                }}
              >
                <option selected disabled hidden>
                  {inputfield.first}
                </option>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
            </span>
          </div>
          <div className="text-center">
            <div className="filter-pagination mt-3">
              <button
                className="previous-button"
                onClick={handleFirstPage}
                disabled={inputfield.page === 1}
              >
                {"<<"}
              </button>

              <button
                className="previous-button"
                onClick={handlePreviousPage}
                disabled={inputfield.page === 1}
              >
                Previous
              </button>
              <button
                className="next-button"
                onClick={handleNextPage}
                disabled={!paginatorInfo || !paginatorInfo.hasMorePages}
              >
                Next
              </button>
              <button
                className="next-button"
                onClick={handleLastPage}
                disabled={
                  !paginatorInfo || inputfield.page === paginatorInfo.lastPage
                }
              >
                {">>"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Job;
