import axios from "axios";
import swal from "sweetalert";
import { loginConfirmedAction } from "../store/actions/AuthActions";

export function signUp(
  email,
  password,
  fname,
  lname,
  type,
  address,
  carehome_id,
  image
) {
  //axios call
  const postData = {
    email,
    password,
    fname,
    lname,
    type,
    address,
    carehome_id,
    image,
    returnSecureToken: true,
  };
  return axios.post(
    `https://api.avanzando.dev-iuh.xyz/api/professionals`,
    postData
  );
}

export function login(email, password) {
  const postData = {
    email,
    password,
    type: "super_judge",
    returnSecureToken: true,
  };
  return axios.post(`https://api.thefinal9.dev-iuh.xyz/api/login`, postData);
}

export function formatError(errorResponse) {
  switch (errorResponse) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "The selected email is invalid.":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "Invalid Credentials":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("userDetails", tokenDetails);
}

// export function runLogoutTimer(dispatch, timer, history) {
//   setTimeout(() => {
//     dispatch(logout(history));
//   }, timer);
// }

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem("userDetails");
  // let tokenDetails = "";
  if (!tokenDetailsString) {
    // dispatch(logout(history));
    return;
  }

  // let expireDate = new Date(tokenDetails.expireDate);
  // let todaysDate = new Date();

  // if (todaysDate > expireDate) {
  //   dispatch(logout(history));
  //   return;
  // }
  dispatch(loginConfirmedAction(tokenDetailsString));

  // const timer = expireDate.getTime() - todaysDate.getTime();
  // runLogoutTimer(dispatch, timer, history);
}
