/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import { ThemeContext } from "../../../context/ThemeContext";
import { useQuery } from "@apollo/client";
import { DASHBOARD } from "../../../Graphql/Queries";

const Home = () => {
  const { data, loading, refetch: queryRefetch } = useQuery(DASHBOARD);
  const { changeBackground } = useContext(ThemeContext);
  console.log(data);
  useEffect(() => {
    queryRefetch();
    changeBackground({ value: "light", label: "Light" });
  }, []);
  return (
    <>
      {loading ? <div className="loader" /> : null}
      <div className="row">
        <div className="col-xl-3 col-sm-6">
          <div className="card gradient-1 card-bx">
            <div className="card-body d-flex align-items-center">
              <div className="me-auto text-white">
                <h2 className="text-white">{data?.dashboardStat?.userCount}</h2>
                <span className="fs-18">Users</span>
              </div>
              <img src="/img/man.png" width={"45%"} alt="" />
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6">
          <div className="card gradient-2 card-bx">
            <div className="card-body d-flex align-items-center">
              <div className="me-auto text-white">
                <h2 className="text-white">{data?.dashboardStat?.jobCount}</h2>
                <span className="fs-18">Jobs</span>
              </div>
              <img src="/img/job.png" width={"45%"} alt="" />
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6">
          <div className="card gradient-3 card-bx">
            <div className="card-body d-flex align-items-center">
              <div className="me-auto text-white">
                <h2 className="text-white">
                  {data?.dashboardStat?.acceptedJobCount}
                </h2>
                <span className="fs-18">Approved Jobs</span>
              </div>
              <img src="/img/approved.png" width={"45%"} alt="" />
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6">
          <div className="card gradient-4 card-bx">
            <div className="card-body d-flex align-items-center">
              <div className="me-auto text-white">
                <h2 className="text-white">
                  {data?.dashboardStat?.pendingJobCount}
                </h2>
                <span className="fs-18">Pending Jobs</span>
              </div>
              <img src="/img/pending.png" width={"45%"} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
