import React, { useContext } from "react";

/// React router dom
import { Route, Routes } from "react-router-dom";

/// Css
import "./chart.css";
import "./index.css";
import "./step.css";

/// Layout
import { ThemeContext } from "../context/ThemeContext";
import Home from "./components/Dashboard/Home";
import Job from "./components/table/FilteringTable/Job";
import Recruiters from "./components/table/FilteringTable/Recuirter";
import User from "./components/table/FilteringTable/User";
import Industry from "./components/table/FilteringTable/industry";
import Footer from "./layouts/Footer";
import Setting from "./layouts/Setting";
import Nav from "./layouts/nav";
import Packages from "./components/table/FilteringTable/Packages";
import AgreeUser from "./components/table/FilteringTable/AgreeUser";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    { url: "Users", component: User },
    { url: "agreeuser", component: AgreeUser },

    // { url: "Category", component: Category },
    // { url: "subcategory", component: SubCategory },
    { url: "Jobs", component: Job },
    { url: "industry", component: Industry },
    { url: "/recruiters", component: Recruiters },
    { url: "/packages", component: Packages },
    
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "vh-100"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div className={`${!pagePath ? "container-fluid" : ""}`}>
            <Routes>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  element={<data.component />}
                />
              ))}
            </Routes>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <Setting />
    </>
  );
};

export default Markup;
