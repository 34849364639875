/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";
//import './table.css';
import { useMutation, useQuery } from "@apollo/client";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  ADD_PACKAGE,
  DELETE_PACKAGE,
  UPDATE_PACKAGE,
} from "../../../../Graphql/Mutations";
import { GET_ALL_PACKAGES } from "../../../../Graphql/Queries";
import "./filtering.css";
export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <input
        className="form-control input-search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div>
      Search :{" "}
      <input
        className="ml-2 input-search form-control"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        style={{ width: "20%" }}
      />
    </div>
  );
};

const Packages = () => {
  const keys = [
    "id",
    "name",
    "description",
    "price",
    "active",
    "period",
    "periodType",
  ];
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [inputfield, setInputfield] = useState({
    name: "",
    description: "",
    price: "",
    active: true,
    period: 0,
    periodType: "",
  });
  const COLUMNS = [
    ...keys
      .filter((s) => s !== "active")
      .map((key) => ({
        Header: key === "name" ? "Package Name" : key,
        Footer: key === "name" ? "Package Name" : key,
        accessor: key,
        Filter: ColumnFilter,
      })),
    {
      Header: "Active",
      Footer: "Active",
      accessor: "active",
      Cell: ({ value, row }) => {
        return (
          <>
            <span
              onClick={() => updatePackage(row.original)}
              class={`c-pointer badge bg-${value ? "success" : "danger"}`}
            >
              {value ? "Active" : "De-Active"}{" "}
            </span>
          </>
        );
      },
    },
    {
      Header: "Action",
      Footer: "Action",
      // accessor: "active",
      Cell: ({ row }) => {
        return (
          <>
            <FaTrash
              size={20}
              color="red"
              onClick={() => deletePackage(row.original.id)}
            />
          </>
        );
      },
    },
  ];
  const {
    data: Packageplan,
    error,
    loading,
    refetch: refetchQueries,
  } = useQuery(GET_ALL_PACKAGES);
  const [createPlan] = useMutation(ADD_PACKAGE, {
    refetchQueries: GET_ALL_PACKAGES,
  });
  const [deletePlan] = useMutation(DELETE_PACKAGE, {
    refetchQueries: GET_ALL_PACKAGES,
  });
  const [updatePlan] = useMutation(UPDATE_PACKAGE, {
    refetchQueries: GET_ALL_PACKAGES,
  });
  useEffect(() => {
    if (!loading && !error) {
      setData(Packageplan.plans);
    } else toast.error(error);
  }, [Packageplan, loading]);
  const [columns] = useState(COLUMNS);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;
  const { globalFilter, pageIndex } = state;
  const onShow = () => setShow(true);
  const onHide = () => setShow(false);
  const updatePackage = (data) => {
    const newData = { ...data }; // Create a copy of the data to avoid mutating the original
    delete newData.__typename; // Remove the '__typename' key
    newData.updatePlanId = newData.id; // Renaming key name
    newData.active = !newData.active; // Changing statue
    updatePlan({ variables: newData })
      .then((result) => {
        if (result && result.data) {
          refetchQueries();
          toast.success("Package Updated Successfully");
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((errors) => {
        toast.error(errors + "");
        console.error(errors);
      });
  };
  const deletePackage = (id) => {
    deletePlan({ variables: { deletePlanId: id } })
      .then((result) => {
        if (result && result.data) {
          refetchQueries();
          toast.success("Package Deleted Successfully");
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((errors) => {
        toast.error(errors + "");
        console.error(errors);
      });
  };
  const AddPackage = () => {
    createPlan({ variables: inputfield })
      .then((result) => {
        if (result && result.data) {
          onHide();
          refetchQueries();
          setInputfield({
            name: "",
            description: "",
            price: "",
            active: true,
            period: 0,
            periodType: "",
          });
          toast.success("Package Added Successfully");
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((errors) => {
        toast.error(errors + "");
        console.error(errors);
      });
  };
  const handleChange = (event) => {
    const { value, name } = event.target;
    if (name === "active" && value === "true") {
      setInputfield({ ...inputfield, [name]: true });
    } else if (name === "active" && value === "false") {
      setInputfield({ ...inputfield, [name]: false });
    } else if (name === "period") {
      setInputfield({ ...inputfield, [name]: Number(value) });
    } else setInputfield({ ...inputfield, [name]: value });
  };
  return (
    <>
      {loading ? <div className="loader"></div> : null}
      <div className="card">
        <div>
          <div className="card-header">
            <h4 className="card-title float-left">Packages</h4>
            <div className="btn btn-primary float-end" onClick={onShow}>
              Add Packages
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table
              {...getTableProps()}
              id="table-to-export" // Add the id attribute
              className="table display"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {/* {column.canFilter ? column.render("Filter") : null} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
              {""}
            </span>
            <span className="table-index">
              Go to page :{" "}
              <input
                type="number"
                className="ml-2"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />
            </span>
          </div>
          <div className="text-center">
            <div className="filter-pagination  mt-3">
              <button
                className=" previous-button"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>

              <button
                className="previous-button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous
              </button>
              <button
                className="next-button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next
              </button>
              <button
                className=" next-button"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={onHide} size="md">
        <Modal.Header closeButton>Add Packages</Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              {keys
                .filter(
                  (s) => s !== "id" && s !== "active" && s !== "periodType"
                )
                .map((e) => (
                  <div className={`mb-3 col-md-6 col-sm-6`}>
                    <h5 className="text-capitalize">{e}</h5>
                    <input
                      type={e.period || e.price ? "number" : "text"}
                      name={e}
                      value={inputfield[e]}
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                ))}
              <div className={`mb-3 col-md-6 col-sm-6`}>
                <h5 className="text-capitalize">Active</h5>
                <select
                  name={"active"}
                  className="form-control"
                  onChange={handleChange}
                >
                  <option disabled hidden selected>
                    ....
                  </option>{" "}
                  <option value={true}>Active</option>{" "}
                  <option value={false}>De-Active</option>{" "}
                </select>
              </div>
              <div className={`mb-3 col-md-6 col-sm-6`}>
                <h5 className="text-capitalize">Period Type</h5>
                <select
                  name={"periodType"}
                  className="form-control"
                  onChange={handleChange}
                >
                  <option disabled hidden selected>
                    ....
                  </option>{" "}
                  <option value={"Day"}>Day</option>{" "}
                  <option value={"Week"}>Week</option>{" "}
                  <option value={"Month"}>Month</option>{" "}
                  <option value={"Year"}>Year</option>{" "}
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onHide}>
            Close
          </Button>
          <Button variant="success" onClick={AddPackage}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Packages;
