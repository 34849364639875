/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";

//import './table.css';
import { useQuery } from "@apollo/client";
import { GET_USER_LIST } from "../../../../Graphql/Queries";
import "./filtering.css";
export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <input
        className="form-control input-search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div>
      Search :{" "}
      <input
        className="ml-2 input-search form-control"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        style={{ width: "20%" }}
      />
    </div>
  );
};

export const User = () => {
  const [data, setData] = useState([]);
  const [inputfield, setInputfield] = useState({
    first: 10,
    page: 1,
  });

  const { data: queryData, loading } = useQuery(GET_USER_LIST, {
    variables: inputfield,
  });

  const paginatorInfo = queryData?.users?.paginatorInfo;
  useEffect(() => {
    if (!loading) {
      setData(queryData?.users?.data);
    }
  }, [queryData, loading]);
  const keys = ["id", "first_name", "last_name", "email"];

  const COLUMNS = [
    ...keys.map((key) => ({
      Header: key.replace(/_/g, " "),
      Footer: key.replace(/_/g, " "),
      accessor: key,
      Filter: ColumnFilter,
    })),

    {
      Header: "Role",
      Footer: "Role",
      accessor: "role.name",
      Cell: ({ value }) => {
        return (
          <>
            <span class={`badge bg-success`}>{value} </span>
          </>
        );
      },
    },
    // {
    //   Header: "Action",
    //   Footer: "Action",
    //   Cell: ({ row }) => {
    //     return (
    //       <>
    //         <div className="d-flex ">
    //           <Link
    //             to={`/JudgesSong/${row.original.id}/${row.original.title}`}
    //             title="View Songs"
    //             className={`btn btn-outline-light btn-sm rounded`}
    //           >
    //             <i className="fa fa-microphone" />
    //           </Link>
    //           <button
    //             onClick={() => {
    //               onShow();
    //               setEvent(row.original.id);
    //             }}
    //             title="View Event"
    //             className={`btn btn-outline-success btn-sm rounded`}
    //           >
    //             <i className="fa fa-calendar" />
    //           </button>
    //         </div>
    //       </>
    //     );
    //   },
    // },
  ];

  const [columns] = useState(COLUMNS);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,

    setGlobalFilter,
  } = tableInstance;

  const { globalFilter } = state;

  const handleFirstPage = () => {
    setInputfield({ ...inputfield, page: 1 });
  };

  const handlePreviousPage = () => {
    setInputfield({ ...inputfield, page: inputfield.page - 1 });
  };

  const handleNextPage = () => {
    setInputfield({ ...inputfield, page: inputfield.page + 1 });
  };

  const handleLastPage = () => {
    setInputfield({ ...inputfield, page: paginatorInfo.lastPage });
  };

  useEffect(() => {
    // Update the inputfield state when the paginator data changes
    if (paginatorInfo) {
      setInputfield((prevInputfield) => ({
        ...prevInputfield,
        page: paginatorInfo.currentPage,
      }));
    }
  }, [paginatorInfo]);

  return (
    <>
      {loading ? <div className="loader"></div> : null}
      <div className="card">
        <div>
          <div className="card-header">
            <h4 className="card-title float-left">Users</h4>
          </div>
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table
              {...getTableProps()}
              id="table-to-export" // Add the id attribute
              className="table display"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {/* {column.canFilter ? column.render("Filter") : null} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between">
            <span>
              Page{" "}
              <strong>
                {paginatorInfo?.currentPage} of {paginatorInfo?.total}
              </strong>
              {""}
            </span>
            <span className="table-index">
              View Per page :{" "}
              <input
                type="number"
                className="ml-2"
                defaultValue={inputfield.first}
                onChange={(e) => {
                  const pageNumber = e.target.value;
                  setInputfield({
                    ...inputfield,
                    first: Number(pageNumber),
                  });
                }}
              />
            </span>
          </div>
          <div className="text-center">
            <div className="filter-pagination mt-3">
              <button
                className="previous-button"
                onClick={handleFirstPage}
                disabled={inputfield.page === 1}
              >
                {"<<"}
              </button>

              <button
                className="previous-button"
                onClick={handlePreviousPage}
                disabled={inputfield.page === 1}
              >
                Previous
              </button>
              <button
                className="next-button"
                onClick={handleNextPage}
                disabled={!paginatorInfo || !paginatorInfo.hasMorePages}
              >
                Next
              </button>
              <button
                className="next-button"
                onClick={handleLastPage}
                disabled={
                  !paginatorInfo || inputfield.page === paginatorInfo.lastPage
                }
              >
                {">>"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default User;
