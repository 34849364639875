import { gql } from "@apollo/client";

export const ADMIN_LOGIN = gql`
  mutation adminLogin($email: String!, $password: String!) {
    adminLogin(email: $email, password: $password) {
      token
      user {
        id
        email
        first_name
        last_name
        role {
          name
          id
        }
      }
    }
  }
`;
export const ADD_SUB_CATEGORY = gql`
  mutation createSubCategory(
    $category_id: Int!
    $name: String!
    $description: String!
  ) {
    createSubCategory(
      category_id: $category_id
      name: $name
      description: $description
    ) {
      __typename
    }
  }
`;
export const DELETE_SUB_CATEGORY = gql`
  mutation deleteSubCategory($id: ID!) {
    deleteSubCategory(id: $id) {
      id
      name
    }
  }
`;
export const ADD_CATEGORY = gql`
  mutation createCategory($name: String!) {
    createCategory(name: $name) {
      __typename
    }
  }
`;
export const DELETE_CATEGORY = gql`
  mutation deleteCategory($id: ID!) {
    deleteCategory(id: $id) {
      id
      name
    }
  }
`;
export const APPROVE_JOB = gql`
  mutation approveJob($id: ID!) {
    approveJob(id: $id) {
      is_approved
    }
  }
`;
export const REFUSE_JOB = gql`
  mutation rejectJob($id: ID!) {
    rejectJob(id: $id) {
      is_rejected
    }
  }
`;
export const ADD_INDUSTRY = gql`
  mutation CreateCompanyIndustry($name: String!, $image: Upload!) {
    createCompanyIndustry(name: $name, image: $image) {
      id
    }
  }
`;
export const ADD_PACKAGE = gql`
  mutation CreatePlan(
    $name: String!
    $description: String!
    $price: String!
    $active: Boolean!
    $period: Int!
    $periodType: String!
  ) {
    createPlan(
      name: $name
      description: $description
      price: $price
      active: $active
      period: $period
      periodType: $periodType
    ) {
      id
    }
  }
`;
export const UPDATE_PACKAGE = gql`
  mutation UpdatePlan(
    $updatePlanId: ID!
    $name: String!
    $description: String!
    $price: String!
    $active: Boolean!
    $period: Int!
    $periodType: String!
  ) {
    updatePlan(
      id: $updatePlanId
      name: $name
      description: $description
      price: $price
      active: $active
      period: $period
      periodType: $periodType
    ) {
      id
    }
  }
`;
export const DELETE_PACKAGE = gql`
  mutation DeletePlan($deletePlanId: ID!) {
    deletePlan(id: $deletePlanId) {
      id
    }
  }
`;
export const DELETE_INDUSTRY = gql`
  mutation DeleteCompanyIndustry($deleteCompanyIndustryId: ID!) {
    deleteCompanyIndustry(id: $deleteCompanyIndustryId) {
      id
    }
  }
`;
