/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";

import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";

//import './table.css';
import { useMutation, useQuery } from "@apollo/client";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { DELETE_INDUSTRY } from "../../../../Graphql/Mutations";
import { GET_ALL_INDUSTRIES } from "../../../../Graphql/Queries";
import "./filtering.css";
export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <input
        className="form-control input-search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div>
      Search :{" "}
      <input
        className="ml-2 input-search form-control"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        style={{ width: "20%" }}
      />
    </div>
  );
};

const Industry = () => {
  const keys = ["id", "name"];
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [deleteCompanyIndustry] = useMutation(DELETE_INDUSTRY, {
    refetchQueries: GET_ALL_INDUSTRIES,
  });
  const [inputfield, setInputfield] = useState({
    name: "",
    image: undefined,
  });
  const COLUMNS = [
    ...keys.map((key) => ({
      Header: key === "name" ? "Industry Name" : key,
      Footer: key === "name" ? "Industry Name" : key,
      accessor: key,
      Filter: ColumnFilter,
    })),
    {
      Header: "Action",
      Footer: "Action",
      Cell: ({ row }) => {
        return (
          <>
            <FaTrash
              size={20}
              color="red"
              onClick={() => deleteindestry(row.original.id)}
            />
          </>
        );
      },
    },
  ];

  const {
    data: companyIndustries,
    error,
    loading,
    refetch: refetchQueries,
  } = useQuery(GET_ALL_INDUSTRIES);

  useEffect(() => {
    if (!loading && !error) {
      setData(companyIndustries.companyIndustries);
    } else toast.error(error);
  }, [companyIndustries, loading]);
  const [columns] = useState(COLUMNS);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;
  const { globalFilter, pageIndex } = state;
  const onShow = () => setShow(true);
  const onHide = () => setShow(false);
  const AddIndustry = async () => {
    const formData = new FormData();
    const mutation = `mutation CreateCompanyIndustry($name: String!, $image: Upload!) {
      createCompanyIndustry(name: $name, image: $image) {
        id
      }
    }`;
    const query = {
      query: mutation,
      variables: {
        name: inputfield?.name,
      },
    };

    formData.append("operations", JSON.stringify(query));
    formData.append("map", '{"0": ["variables.image"]}');
    formData.append("0", inputfield?.image);

    try {
      const response = await fetch("https://creative.dev-rr.xyz/graphql", {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userDetails")}`,
        },
      });
      const responseData = await response.json();
      if (responseData.data) {
        onHide();
        refetchQueries();
        toast.success("Category Added Successfully");
      }
    } catch (error) {
      toast.error(error);
    }
  };
  const handleChange = (event) => {
    const { value, name, files } = event.target;
    if (name === "image") {
      const file = files[0];
      setInputfield({ ...inputfield, [name]: file });
    } else {
      setInputfield({ ...inputfield, [name]: value });
    }
  };

  const deleteindestry = (id) => {
    deleteCompanyIndustry({ variables: { deleteCompanyIndustryId: id } })
      .then((result) => {
        if (result && result.data) {
          refetchQueries();
          toast.success("Industry Deleted Successfully");
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((errors) => {
        toast.error(errors + "");
        console.error(errors);
      });
  };
  return (
    <>
      {loading ? <div className="loader"></div> : null}
      <div className="card">
        <div>
          <div className="card-header">
            <h4 className="card-title float-left">Industry</h4>
            <div className="btn btn-primary float-end" onClick={onShow}>
              Add Industry
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table
              {...getTableProps()}
              id="table-to-export" // Add the id attribute
              className="table display"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {/* {column.canFilter ? column.render("Filter") : null} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
              {""}
            </span>
            <span className="table-index">
              Go to page :{" "}
              <input
                type="number"
                className="ml-2"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />
            </span>
          </div>
          <div className="text-center">
            <div className="filter-pagination  mt-3">
              <button
                className=" previous-button"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>

              <button
                className="previous-button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous
              </button>
              <button
                className="next-button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next
              </button>
              <button
                className=" next-button"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={onHide} size="md">
        <Modal.Header closeButton>Add Industry</Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className={`mb-3 col-md-12 col-sm-12`}>
                <h5 className="text-capitalize">Industry Name</h5>
                <input
                  type={"text"}
                  name="name"
                  className="form-control"
                  onChange={handleChange}
                />
              </div>
              <div className={`mb-3 col-md-12 col-sm-12`}>
                <h5 className="text-capitalize">Upload Image</h5>
                <input
                  type={"file"}
                  name="image"
                  className="form-control p-3"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onHide}>
            Close
          </Button>
          <Button variant="success" onClick={AddIndustry}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Industry;
