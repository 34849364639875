import { gql } from "@apollo/client";
export const GET_USER_LIST = gql`
  query usersList($first: Int!, $page: Int) {
    users(first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        lastPage
        hasMorePages
        __typename
        total
      }
      data {
        email
        first_name
        id
        last_name
        role {
          name
        }
      }
    }
  }
`;
export const GET_RECRUITERS_LIST = gql`
  query Recruiters($first: Int!, $page: Int) {
    recruiters(first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        lastPage
        hasMorePages
        __typename
        total
      }
      data {
        email
        first_name
        id
        last_name
        role {
          name
        }
      }
    }
  }
`;
export const GET_Categories = gql`
  query categories {
    categories {
      name
      id
    }
  }
`;
export const DASHBOARD = gql`
  query {
    dashboardStat {
      userCount
      jobCount
      acceptedJobCount
      pendingJobCount
    }
  }
`;
export const GET_SUB_Categories = gql`
  query subcategories {
    subcategories {
      id
      name
      description
      category {
        name
      }
    }
    categories {
      id
      name
    }
  }
`;
export const GET_ALL_JOBS = gql`
  query appliedJobs(
    $first: Int!
    $page: Int
    $is_approved: Boolean
    $is_rejected: Boolean
  ) {
    appliedJobs(
      first: $first
      page: $page
      is_approved: $is_approved
      is_rejected: $is_rejected
    ) {
      paginatorInfo {
        count
        currentPage
        lastPage
        hasMorePages
        __typename
        total
      }
      data {
        id
        is_approved
        is_rejected
        cv
        user {
          first_name
          last_name
          email
          phone
          profile_image
          job_title
        }
        dob
        country
        city
        address
      }
    }
  }
`;
export const GET_ALL_INDUSTRIES = gql`
  query CompanyIndustries {
    companyIndustries {
      id
      name
    }
  }
`;
export const GET_ALL_PACKAGES = gql`
  query Plans($name: String) {
    plans(name: $name) {
      id
      name
      description
      price
      active
      period
      periodType
    }
  }
`;
