import {
  LOADING_TOGGLE_ACTION,
  LOGIN_CONFIRMED_ACTION,
  LOGIN_FAILED_ACTION,
  LOGOUT_ACTION,
  SIGNUP_CONFIRMED_ACTION,
  SIGNUP_FAILED_ACTION,
} from "../actions/AuthActions";

const initialState = {
  auth: {
    email: "",
    idToken: "",
    localId: "",
    expiresIn: "",
    refreshToken: "",
    is_Featured: false,
    is_Featured_Paid_Status: "",
    professionalType: "",
  },
  errorMessage: "",
  successMessage: "",
  showLoading: false,
};

export function AuthReducer(state = initialState, action) {
  if (action.type === SIGNUP_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: action.payload,
      errorMessage: "",
      successMessage: "Signup Successfully Completed",
      showLoading: false,
    };
  }
  if (action.type === LOGIN_CONFIRMED_ACTION) {
    console.log(state, "reducer 1");
    console.log(action, "reducer 2");
    console.log(typeof action.payload, "reducer 3");
    if (typeof action.payload === "object") {
      return {
        ...state,
        auth: {
          email: action.payload?.user?.email,
          idToken: action.payload.token,
          localId: action.payload.user.id,
          name:
            action.payload.user.first_name +
            " " +
            action.payload.user.last_name,
          professionalType: "Admin",
        },
        errorMessage: "",
        successMessage: "Login Successfully Completed",
        showLoading: false,
      };
    }
    // return {
    //   ...state,
    //   auth: {
    //     email: state?.email,
    //     idToken: action.payload.data.token,
    //     localId: state.id,
    //   },
    //   errorMessage: "",
    //   successMessage: "Login Successfully Completed",
    //   showLoading: false,
    // };
  }

  if (action.type === LOGOUT_ACTION) {
    return {
      ...state,
      errorMessage: "",
      successMessage: "",
      auth: {
        email: "",
        idToken: "",
        localId: "",
        expiresIn: "",
        refreshToken: "",
      },
    };
  }

  if (
    action.type === SIGNUP_FAILED_ACTION ||
    action.type === LOGIN_FAILED_ACTION
  ) {
    return {
      ...state,
      errorMessage: action.payload,
      successMessage: "",
      showLoading: false,
    };
  }

  if (action.type === LOADING_TOGGLE_ACTION) {
    return {
      ...state,
      showLoading: action.payload,
    };
  }
  return state;
}
